import find from 'lodash/find'
import keys from 'lodash/keys'
import includes from 'lodash/includes'
import reject from 'lodash/reject'
import map from 'lodash/map'
import flatten from 'lodash/flatten'
import filter from 'lodash/filter'
import castArray from 'lodash/castArray'
import max from 'lodash/max'
import isEmpty from 'lodash/isEmpty'
import min from 'lodash/min'

import * as FormatTrackerChart from 'services/format_tracker_chart'
import { MARKETPLACES } from 'constants/countries'
import { updateInArray, updateAllInArray } from '../arrays'
import { getCurrencyCode } from '../currency'
import { isAsin } from '../amazon'
import { calculateAverages } from './averages'
import { numberOfDaysXMonthsFromNow } from 'helpers/date'

export * from './averages'

export const parseCountryFromUrl = url => {
  const res = url.match(/amazon.([\w|.]*)/)

  if (!res) {
    return null
  }
  let code = res[1]

  switch (code) {
    case 'com':
      code = 'us'
      break
    case 'co.uk':
      code = 'uk'
      break
    case 'com.mx':
      code = 'mx'
      break
    default:
      const validCodes = keys(MARKETPLACES)
      if (!includes(validCodes, code)) {
        return
      }
      break
  }

  return code
}

export const findCategory = (tracker, id) => {
  return find(tracker.trackedProducts, {
    category_id: id
  })
}

export const visibleCategoryLight = (trackedProducts, activeTab) => {
  return find(trackedProducts, {
    category_name: activeTab
  })
}

export const categoryLoading = category =>
  category &&
  (category.isLoading ||
    (category.products.length &&
      category.products.every(product => product.isLoading)))

export const visibleCategory = tracker => {
  return visibleCategoryLight(tracker.trackedProducts, tracker.activeTab)
}

export const visibleProductsLight = (trackedProducts, activeTab) => {
  const category = visibleCategoryLight(trackedProducts, activeTab)
  return category
    ? category.products.slice(0, category.numberVisible || 10)
    : []
}

export const visibleProducts = tracker => {
  return visibleProductsLight(tracker.trackedProducts, tracker.activeTab)
}

export const customCategories = trackedProducts => {
  return trackedProducts.filter(
    category => category.category_name !== 'Ungrouped'
  )
}

export const shouldShowSampleCategory = (count, categories) => {
  return (
    count === 0 &&
    categories.length > 1 &&
    categories[0].products.length === 0 &&
    categories[1].category_id === 'sample'
  )
}

export const shouldReloadAllCategories = (tracker, days = null) => {
  const { activeTab, selectedMonths, overviewMaxDataRange } = tracker
  const selectedDays = numberOfDaysXMonthsFromNow(selectedMonths)
  return (
    activeTab === 'Overview' &&
    parseInt(days || selectedDays, 10) > parseInt(overviewMaxDataRange, 10)
  )
}

export const shouldReloadCategory = tracker => {
  const category = visibleCategory(tracker)
  return (
    category &&
    category.category_name !== 'Overview' &&
    !category.isLoading &&
    numberOfDaysXMonthsFromNow(tracker.selectedMonths) >
      (category.maxDataRange || 0)
  )
}

export const validateCategory = (categories, name) => {
  const names = map(categories, category =>
    category.category_name.toLowerCase()
  )

  if (!name.trim()) {
    return {
      ok: false,
      message: 'Name cannot be blank.'
    }
  }
  if (includes(names, name.toLowerCase())) {
    return {
      ok: false,
      message: 'Name is already taken.'
    }
  }
  return {
    ok: true
  }
}

export const removeFromCategory = (categories, categoryName, ids) => {
  const category = find(categories, {
    category_name: categoryName
  })
  const updatedProductList = reject(category.products, product =>
    includes(ids, product.id)
  )
  const averages = calculateAverages(updatedProductList)
  const numberVisible = min([
    updatedProductList.length,
    category.numberVisible - 1
  ])

  return updateInArray(
    categories,
    {
      category_name: categoryName
    },
    {
      products: updatedProductList,
      averages,
      numberVisible
    }
  )
}

export const addToCategory = (categories, categoryName, products) => {
  const category = find(categories, {
    category_name: categoryName
  })
  const updatedProductList = flatten([products, ...category.products])
  const averages = calculateAverages(updatedProductList)
  const numberVisible = min([
    updatedProductList.length,
    category.numberVisible + 1
  ])

  return updateInArray(
    categories,
    {
      category_name: categoryName
    },
    {
      products: updatedProductList,
      averages,
      numberVisible
    }
  )
}

export const formatCategory = (category, selectedDays = null) => {
  const products = getCategoryProducts(category)
  const numberVisible = min([products.length, 20])
  const formattedProducts = products.map(formatProduct)
  const averages = calculateAverages(formattedProducts)
  const maxDataRange = selectedDays
    ? max([parseInt(category.maxDataRange, 10), parseInt(selectedDays, 10)])
    : category.maxDataRange
  const category_name =
    category.category_name === 'Uncategorized'
      ? 'Ungrouped'
      : category.category_name
  return {
    ...category,
    category_name,
    numberVisible,
    products: formattedProducts,
    averages,
    maxDataRange,
    currencyCode: getCurrencyCode(averages.marketplace || 'us'),
    isLoading: false
  }
}

export const updateCategoryAverages = (trackedProducts, name) => {
  const category = find(
    trackedProducts,
    category => category.category_name === name
  )

  if (category) {
    return updateInArray(
      trackedProducts,
      {
        category_name: name
      },
      formatCategory(category)
    )
  }
  return trackedProducts
}

export const findProductCategory = (categories, key, value) => {
  for (const category of categories) {
    if (find(category.products, product => product[key] === value)) {
      return category
    }
  }
}

export const updateProductTrack = (
  categories,
  categoryName,
  key,
  newProperties
) => {
  const category = find(categories, {
    category_name: categoryName
  })
  const updatedProductList = updateInArray(
    category.products,
    key,
    newProperties
  )

  return updateInArray(
    categories,
    {
      category_name: categoryName
    },
    {
      products: updatedProductList
    }
  )
}

export const updateAllProductTracks = (tracker, newProperties) => {
  const { trackedProducts, activeTab, asinSearchTerm } = tracker

  const category = visibleCategory(tracker)
  let updatedProductList = []

  if (isEmpty(asinSearchTerm)) {
    updatedProductList = updateAllInArray(category.products, newProperties)
  } else {
    updatedProductList = updateInArray(
      category.products,
      {
        asin: asinSearchTerm
      },
      newProperties
    )
  }

  return updateInArray(
    trackedProducts,
    {
      category_name: activeTab
    },
    {
      products: updatedProductList
    }
  )
}

export const categorize = (
  categories,
  sourceCategoryName,
  targetCategoryName,
  products
) => {
  const ids = map(castArray(products), 'id')
  const updatedRecords = removeFromCategory(categories, sourceCategoryName, ids)
  return addToCategory(updatedRecords, targetCategoryName, products)
}

export const getNumberOfCheckedTracks = (categories, activeTab) => {
  const category = find(categories, {
    category_name: activeTab
  })
  return filter(category.products, {
    checked: true
  }).length
}

export const formatCategories = (trackedProducts, selectedDays = null) => {
  return trackedProducts.map(category => formatCategory(category, selectedDays))
}

const getCategoryProducts = category => {
  if (category.products) {
    return category.products
  }

  if (category.product_tracks) {
    return category.product_tracks.map(productTrack => ({
      id: `${productTrack.country}/${productTrack.asin}`,
      isLoading: true
    }))
  }
  return []
}

export const getNumberOfCheckedRecommendedProducts = products => {
  return filter(products, {
    checked: true
  }).length
}

export const formatProduct = product => {
  return {
    ...product,
    storedChartData: FormatTrackerChart.run(product)
  }
}

// Forces number of products visible, or just adds 10 to current count
export const showMoreProductsInCategory = (tracker, n) => {
  const category = visibleCategory(tracker)

  const numberVisible =
    n || min([category.products.length, category.numberVisible + 20])

  return updateInArray(
    tracker.trackedProducts,
    {
      category_id: category.category_id
    },
    {
      numberVisible
    }
  )
}

export const searchQueryForRecommendedProducts = (
  category,
  modal,
  tracker,
  requestedAsins = [],
  // TODO: Named this way to distinguish from the country in Redux state.
  // Should be renamed whenever this function has less dependency on the Redux state.
  countryOverride = null
) => {
  // TODO: This helper function has too much dependency and thus needs to be dumbed
  // down. It currently knows the state structure AND what certain modals do.
  const isEmptyCategory =
    category && (!category.products || category.products.length === 0)
  const isCategoryWithProducts =
    category && category.products && category.products.length > 0
  const dominantMarket =
    countryOverride ||
    (category && category.averages && category.averages.marketplace) ||
    tracker.selectedCountry

  let asins = requestedAsins
  let searchKeywords

  if (isEmptyCategory) {
    const searchTerm = category.category_name && category.category_name.trim()

    if (isAsin(searchTerm) && asins.length === 0) {
      asins = [searchTerm]
    } else {
      searchKeywords = searchTerm || ''
    }
  } else if (isCategoryWithProducts && asins.length === 0) {
    asins = category.products
      // fetch only products that belong to dominant market
      .filter(product => product.country === dominantMarket)
      // fetch ASINS from these products
      .map(product => product.asin)
  }

  const query = {
    similarProductFilters: {
      country: {
        type: 'terms',
        valuesArray: [dominantMarket]
      }
    }
  }

  if (asins && asins.length > 0) {
    query.similarProductFilters.asins = {
      type: 'terms',
      valuesArray: asins
    }
  } else {
    query.similarProductFilters.shingles = {
      column: 'name',
      searchTerm: searchKeywords,
      type: 'shingles'
    }
  }

  return query
}

export const sortByColumnAndDirection = (products, column, direction) => {
  const dir = direction === 'asc' ? 1 : -1
  // sorts columns containing string (name, category) or numeric (price) values
  products = products.sort((productA, productB) => {
    let varA = productA[column]
    let varB = productB[column]
    if (typeof varA === 'string' && typeof varB === 'string') {
      varA = varA.toUpperCase()
      varB = varB.toUpperCase()
    }
    if (varA < varB) {
      return -1 * dir
    }
    if (varA > varB) {
      return 1 * dir
    }
    return 0
  })

  return products
}

export const getProductsToAdd = (
  recommendedProducts = [],
  productFromDatabase = {},
  allTrackedProducts = []
) => {
  let products = []

  if (!isEmpty(recommendedProducts)) {
    products = recommendedProducts.filter(
      product => product.checked && !includes(allTrackedProducts, product.id)
    )
  } else if (
    !isEmpty(productFromDatabase) &&
    !includes(allTrackedProducts, productFromDatabase.id)
  ) {
    products = [productFromDatabase]
  }

  return isEmpty(products) ? null : products
}
