import React from 'react'
import moment from 'moment'
import { renderToStaticMarkup } from 'react-dom/server'
import { Translation } from 'react-i18next'
import { parseCurrency } from 'helpers/currency'
import { isValidNumber } from 'helpers/values'
import { dimensionFormatter, weightFormatter } from 'helpers/formatters'
import { parseVariantProductDifferences } from 'helpers/product'
import { TRACKER_TABLE_COLUMNS } from 'constants/product_tracker/tracker'
import { humanizedDateFromEpoch } from '../formatters'

export class CsvError extends Error {}

const asinColumn = {
  name: 'asin',
  displayName: (
    <Translation>{t => t('product_tracker:Csv.asin', 'ASIN')}</Translation>
  )
}

const categoryColumn = {
  name: 'category',
  displayName: (
    <Translation>
      {t => t('product_tracker:Csv.category', 'Category')}
    </Translation>
  )
}

const insertAt = (array, index, item) => [
  ...array.slice(0, index),
  item,
  ...array.slice(index)
]

const renderCurrencyValue = (value, data, t) => {
  return isValidNumber(value)
    ? parseCurrency(value, data.currency_code)
    : t('product_tracker:Csv.notAvailable', 'N.A.')
}

const renderRoundValue = (value, t) => {
  return isValidNumber(value)
    ? Math.round(value).toLocaleString()
    : t('product_tracker:Csv.notAvailable', 'N.A.')
}

const escapeCharacters = (content, shouldRenderComma = false) => {
  let pattern = new RegExp(/[\s,"]/)
  if (shouldRenderComma) {
    pattern = new RegExp(/[\s"]/)
  }

  if (content.toString().match(pattern)) {
    return `"${content.replace(/"/g, '""')}"`
  }

  return content
}

const renderVariantDifferences = product => {
  const array = parseVariantProductDifferences(product)
  if (!array) return ''
  return array.join(', ')
}

export const buildProductColumn = (data, column, selectedMonths, t) => {
  const averages = data.storedChartData
    ? data.storedChartData[selectedMonths].averages
    : {}
  const identifier = column.name || column.id
  switch (identifier) {
    case 'name':
      return (
        data.name?.replace(/,/g, ' ') ||
        t('product_tracker:Csv.notAvailable', 'N.A.')
      )
    case 'asin':
      return data.asin || t('product_tracker:Csv.notAvailable', 'N.A.')
    case 'category':
      return data.category || t('product_tracker:Csv.notAvailable', 'N.A.')
    case 'net': {
      const value =
        averages.avg_price && data.fees ? averages.avg_price - data.fees : null
      return renderCurrencyValue(value, data, t)
    }
    case 'fees':
    case 'avg_price':
    case 'averageMonthlyRevenue':
    case 'avg_revenue': {
      const value = data[identifier] || averages[identifier]
      return renderCurrencyValue(value, data, t)
    }
    case 'averageMonthlySales':
    case 'avg_rank':
    case 'avg_sales': {
      const value = data[identifier] || averages[identifier]
      return renderRoundValue(value, t)
    }
    case 'nReviews':
      return data.nReviews || 0
    case 'rating':
      return data.rating || 0
    case 'dimensions':
      return `${dimensionFormatter(data)} ${data.tier}`
    case 'listedAt':
      return data.listedAt
        ? humanizedDateFromEpoch(parseInt(data.listedAt, 10))
        : t('product_tracker:Csv.notAvailable', 'N.A.')
    case 'weight':
      return weightFormatter(data)
    case 'listingQualityScore': {
      const value = data.listingQualityScore / 10
      return renderRoundValue(value, t)
    }
    case 'variantDifferences': {
      return renderVariantDifferences(data)
    }
    default:
      throw new CsvError(
        `Product Tracker CSV ${identifier} column not supported`
      )
  }
}

const prepareColumns = columns => {
  let columnsToExport = columns.filter(({ name }) => name !== 'actions')

  const nameIndex = columnsToExport.findIndex(({ name }) => name === 'name')

  // To mimic the Product Info column
  if (nameIndex !== -1) {
    columnsToExport = insertAt(columnsToExport, nameIndex + 1, asinColumn)
    columnsToExport = insertAt(columnsToExport, nameIndex + 2, categoryColumn)
  }

  return columnsToExport
}

const buildProductColumns = (product, columns, selectedMonths, t) => {
  return columns.map(column => {
    const content = buildProductColumn(product, column, selectedMonths, t)
    const shouldRenderComma = column.name === 'variantDifferences'
    return escapeCharacters(content, shouldRenderComma)
  })
}

const buildHeaderColumns = columns => {
  return columns.map(columnData => {
    if (columnData.id !== undefined) {
      return columnData.id
    }

    if (typeof columnData.displayName === 'object') {
      return renderToStaticMarkup(columnData.displayName).replace(
        /(<([^>]+)>)/gi,
        ''
      )
    }
    return columnData.displayName
  })
}

export const formatTrackerForExport = (
  columns,
  products,
  selectedMonths,
  t
) => {
  const date = new Date(Date.now())
  const dateStr = moment(date).format('YYYY-MM-DD h:m:s')
  const title = t(
    'product_tracker:Csv.title',
    'JUNGLE SCOUT PRODUCT TRACKER CSV EXPORT'
  )
  const fileName = t(
    'product_tracker:Csv.fileName',
    'Jungle Scout Product Tracker CSV Export - {{date}}',
    { date: dateStr }
  )
  const timestamp = t(
    'product_tracker:Csv.timestamp',
    'Report Generated at: {{date}}',
    { date: date.toString() }
  )
  const columnsSelectedIds = columns.map(el => el.id || el.name)
  const columnsDisplayInfo = TRACKER_TABLE_COLUMNS.filter(
    el => columnsSelectedIds.indexOf(el.name) !== -1
  )
  const columnsToExport = prepareColumns(columnsDisplayInfo)
  const header = buildHeaderColumns(columnsToExport).join(',')
  const rows = products
    .map(product =>
      buildProductColumns(product, columnsToExport, selectedMonths, t)
    )
    .join('\n')

  const csvContent = `${title}\n${timestamp}\n${header}\n${rows}`

  return { fileName: `${fileName}.csv`, csvContent }
}
